import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharelockCommonModule } from '../../../modules/sharelock-common.module';

@Component({
  selector: 'shrl-icon',
  standalone: true,
  imports: [SharelockCommonModule, MatIconModule],
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  readonly iconName = input.required<string>();
}
